/**
 *  Created by daiwenjuan on 2019/3/21 下午2:09.
 */
import key from './key';
export default [
    {
        id: key.ID,
        reducer: function () { return import('./civil-service.reducer'); }
    },
    {
        point: '@@components',
        namespace: key.ID,
        onload: function () { return [{ key: 'CivilServiceView', component: function () { return import('./civil-service-view'); } }]; }
    }
];
